import { ElementType } from "react";
import { NewLogo, NewLogoIcon } from "@assets/icons/general/NewLogo";
import useRewards from "@hooks/useRewards";
import useToken from "@hooks/useToken";
import { cn, TimeRemaining } from "@lib/utils";
import Link from "next/link";
import { BalanceWallet, CasinoSportsButtons, ChatButton, ChatHeader, LoginRegisterButtons, MenuButton, SearchButton } from ".";
import NotificationDropdown from "./notification-dropdown";
import ProfileDropdown from "./profile-dropdown";
import RewardsDropdown from "./rewards-dropdown";
import { usePreferences } from "@contexts/PreferencesContext";
import RakebackBoost from "@components/rakeback-boost";
import { RewardsCalendarIcon, RewardsDailyIcon, RewardsGiftIcon, RewardsMontlyRewardsIcon, RewardsWeeklyIcon } from "@assets/svgs/RewardsIcons";
type HeaderContentProps = Readonly<{
  isRakeBoostActive: boolean;
  boostProgress: number;
  expireAtRakebackBoost: TimeRemaining;
}>;
type Chest = Readonly<{
  image: ElementType;
  name: string;
  api: "rakeback" | "calendar" | "daily" | "weekly" | "monthly";
}>;
export const CHESTS: Chest[] = [{
  image: RewardsGiftIcon,
  name: "rakeback",
  api: "rakeback"
}, {
  image: RewardsCalendarIcon,
  name: "daily_calendar",
  api: "calendar"
}, {
  image: RewardsDailyIcon,
  name: "daily_bonus",
  api: "daily"
}, {
  image: RewardsWeeklyIcon,
  name: "weekly_bonus",
  api: "weekly"
}, {
  image: RewardsMontlyRewardsIcon,
  name: "monthly_bonus",
  api: "monthly"
}];
export default function HeaderContent({
  isRakeBoostActive,
  boostProgress,
  expireAtRakebackBoost
}: HeaderContentProps) {
  const {
    data: rewardsData
  } = useRewards();
  const token = useToken();
  const {
    preferences,
    updatePreference
  } = usePreferences();
  return <div className="flex h-[68px]" data-sentry-component="HeaderContent" data-sentry-source-file="header-content.tsx">
      <div className={cn("hidden [@media(min-width:1101px)]:flex items-center justify-center gap-2 border-b border-r border-solid border-[#E8E5FF]/[0.05] transition-[width] duration-50 ease-in-out", preferences.miniSide ? "w-[80px]" : "w-[250px]")}>
        <MenuButton onMenuToogle={() => updatePreference("miniSide", !preferences.miniSide)} data-sentry-element="MenuButton" data-sentry-source-file="header-content.tsx" />
        {preferences.miniSide ? null : <CasinoSportsButtons />}
      </div>

      <div className="flex-1 border-b border-solid border-[#E8E5FF]/[0.05]">
        <div className="max-w-[1200px] mx-auto h-full flex justify-between items-center px-4 gap-2">
          <div className="flex items-center space-x-[10px] [@media(max-width:767px)]:space-x-2">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="header-content.tsx">
              <NewLogoIcon height={42} className={cn("[@media(max-width:500px)]:block hidden", preferences.chatOpen && !preferences.miniSide && "[@media(min-width:1101px)_and_(max-width:1320px)]:block")} data-sentry-element="NewLogoIcon" data-sentry-source-file="header-content.tsx" />
              <NewLogo height={42} className={cn("[@media(max-width:500px)]:hidden", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1101px)_and_(max-width:1320px)]:hidden block" : "block")} data-sentry-element="NewLogo" data-sentry-source-file="header-content.tsx" />
            </Link>

            {token && <div className="flex items-center [@media(max-width:767px)]:gap-[0px] gap-[6px] [@media(min-width:1101px)]:hidden">
                <BalanceWallet />
              </div>}
          </div>

          {token && <div className="items-center gap-[6px] hidden [@media(min-width:1101px)]:flex min-w-[242px] [@media(min-width:1100px)_and_(max-width:1320px)]:justify-start [@media(min-width:1100px)_and_(max-width:1320px)]:min-w-[180px] justify-center">
              <BalanceWallet />
            </div>}

          <div className="flex items-center">
            {token ? <div className="flex items-center space-x-[10px] [@media(max-width:450px)]:space-x-0">
                {isRakeBoostActive && <RakebackBoost rakebackRate={rewardsData?.rakeback_boost?.rate} boostType={rewardsData?.rakeback_boost?.type} progress={boostProgress} expiresAt={expireAtRakebackBoost} strokeWidth={1.5} header={true} />}

                <SearchButton />
                <div className="flex items-center [@media(min-width:450px)]:border [@media(min-width:450px)]:border-[#33395C] [@media(min-width:450px)]:border-solid [@media(min-width:450px)]:rounded-md [@media(min-width:450px)]:overflow-hidden">
                  <RewardsDropdown isRakeBoostActive={isRakeBoostActive} boostProgress={boostProgress} expireAtRakebackBoost={expireAtRakebackBoost} />
                  <NotificationDropdown />
                  <ProfileDropdown />
                </div>
                <ChatButton onChatToogle={() => updatePreference("chatOpen", !preferences.chatOpen)} />
              </div> : <div className="flex items-center space-x-[10px]">
                <SearchButton />
                <RewardsDropdown isRakeBoostActive={isRakeBoostActive} boostProgress={boostProgress} expireAtRakebackBoost={expireAtRakebackBoost} />
                <LoginRegisterButtons />
                <ChatButton onChatToogle={() => updatePreference("chatOpen", !preferences.chatOpen)} />
              </div>}
          </div>
        </div>
      </div>
      {preferences.chatOpen && <div className="hidden [@media(min-width:1101px)]:block w-[310px]">
          <ChatHeader isHeader={true} />
        </div>}
    </div>;
}