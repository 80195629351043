import { RewardsCalendarIcon, RewardsDailyIcon, RewardsGiftIcon, RewardsMontlyRewardsIcon, RewardsWeeklyIcon } from "@assets/svgs/RewardsIcons";
import { buttonVariants } from "@components/new-ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@components/new-ui/popover";
import useNow from "@hooks/useNow";
import useRewards from "@hooks/useRewards";
import useToken from "@hooks/useToken";
import { cn, getTimeRemaining, TimeRemaining } from "@lib/utils";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";
import { isAfter } from "date-fns";
import { useSetAtom } from "jotai";
import { ElementType, memo, useEffect, useMemo, useState } from "react";
import { GiftIcon } from "../icons";
import RewardsDropdownContent from "./rewards-dropdown-content";
type RewardsDropdownProps = Readonly<{
  isRakeBoostActive: boolean;
  boostProgress: number;
  expireAtRakebackBoost: TimeRemaining;
}>;
type Chest = Readonly<{
  image: ElementType;
  name: string;
  api: "rakeback" | "calendar" | "daily" | "weekly" | "monthly";
}>;
export const CHESTS: Chest[] = [{
  image: RewardsGiftIcon,
  name: "rakeback",
  api: "rakeback"
}, {
  image: RewardsCalendarIcon,
  name: "daily_calendar",
  api: "calendar"
}, {
  image: RewardsDailyIcon,
  name: "daily_bonus",
  api: "daily"
}, {
  image: RewardsWeeklyIcon,
  name: "weekly_bonus",
  api: "weekly"
}, {
  image: RewardsMontlyRewardsIcon,
  name: "monthly_bonus",
  api: "monthly"
}];
const getDelayMinutes = (api: string) => {
  switch (api) {
    case "daily":
      return 0;
    case "weekly":
      return 5;
    case "monthly":
      return 10;
    default:
      return 0;
  }
};
function RewardsDropdown({
  isRakeBoostActive,
  boostProgress,
  expireAtRakebackBoost
}: RewardsDropdownProps) {
  const {
    data: rewardsData,
    mutate: getRewardsData
  } = useRewards();
  const now = useNow();
  const setActiveDropdown = useSetAtom(activeRewardsDropdownAtom);
  const [isOpen, setIsOpen] = useState(false);
  const token = useToken();
  const closeDropdown = () => {
    setIsOpen(false);
    setActiveDropdown(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const rewardsAvailable = useMemo(() => {
    if (!rewardsData) return 0;
    const availableRewards = CHESTS.reduce((total, chest) => {
      const data = chest.api === "rakeback" ? rewardsData.rakeback : chest.api === "calendar" ? rewardsData?.calendar : rewardsData?.chest_rewards?.[chest.api];
      if (!data) return total;
      const hasSufficientFunds = !!data?.sufficient_funds;
      const claimableDate = new Date(data?.claimable?.replace("Z", ""));
      if (chest.api !== "rakeback" && chest.api !== "calendar") {
        claimableDate.setMinutes(claimableDate.getMinutes() + getDelayMinutes(chest.api));
      }
      const hasExpired = isAfter(now, claimableDate);
      const isAvailable = chest.api === "rakeback" || chest.api === "calendar" ? hasSufficientFunds && hasExpired : hasExpired;
      return total + (isAvailable ? 1 : 0);
    }, 0);
    return availableRewards + (rewardsData?.keys?.count > 0 ? 1 : 0);
  }, [now, rewardsData]);
  const countdowns = useMemo(() => !rewardsData ? {} : CHESTS.reduce((acc, chest) => {
    const data = chest.api === "rakeback" ? rewardsData?.rakeback?.claimable?.replace("Z", "") : chest.api === "calendar" ? rewardsData?.calendar?.claimable?.replace("Z", "") : rewardsData.chest_rewards?.[chest.api]?.claimable?.replace("Z", "");
    return {
      ...acc,
      [chest.api]: getTimeRemaining(now, data)
    };
  }, {}), [rewardsData, now]);
  useEffect(() => {
    if (isOpen) {
      getRewardsData();
    }
  }, [isOpen, getRewardsData]);
  return <Popover open={isOpen} onOpenChange={() => {
    setIsOpen(prev => !prev);
    setActiveDropdown(prev => !prev);
  }} data-sentry-element="Popover" data-sentry-component="RewardsDropdown" data-sentry-source-file="index.tsx">
      <PopoverTrigger className="relative" data-sentry-element="PopoverTrigger" data-sentry-source-file="index.tsx">
        <div className={buttonVariants({
        variant: "dark",
        className: cn("w-[48px] h-[48px] !min-h-[48px] !px-0 !py-0 [&_svg]:size-5 relative", token && "border-none rounded-none [@media(max-width:450px)]:bg-transparent [@media(max-width:450px)]:px-0 [@media(max-width:450px)]:w-[38px] [@media(max-width:450px)]:hover:!bg-transparent")
      })} aria-selected={isOpen}>
          <GiftIcon data-sentry-element="GiftIcon" data-sentry-source-file="index.tsx" />
        </div>
        {rewardsAvailable >= 1 && <span className="absolute top-1 right-1 min-w-[17px] h-[17px] flex items-center justify-center text-[11px] rounded-full border border-solid [@media(max-width:450px)]:-right-0.5 border-white/50 bg-gradient-to-b from-[#7DD934] to-[#7DD934] text-white shadow-[0px_0px_12px_0px_rgba(0,119,219,0.5),0px_0px_6px_0px_rgba(0,0,0,0.3)] transition-all duration-300 ease">
            {rewardsAvailable}
          </span>}
      </PopoverTrigger>
      <PopoverContent align="end" data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
        <RewardsDropdownContent countdowns={countdowns} closeDropdown={closeDropdown} isRakeBoostActive={isRakeBoostActive} boostProgress={boostProgress} expireAtRakebackBoost={expireAtRakebackBoost} data-sentry-element="RewardsDropdownContent" data-sentry-source-file="index.tsx" />
      </PopoverContent>
    </Popover>;
}
export default memo(RewardsDropdown);