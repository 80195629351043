import { LoadingSimpleSpinner } from "@components/Loading/LoadingSimpleSpinner";
import { buttonVariants } from "@components/new-ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@components/new-ui/popover";
import useLanguage from "@hooks/useLanguage";
import { useCallback, useEffect, useRef, useState } from "react";
import useWallet from "@hooks/useWallet";
import usePopup from "@hooks/usePopup";
import { NotificationIcon } from "../icons";
import { useRouter } from "next/router";
import useSocket from "@hooks/useSocket";
import { NotificationItem, NotificationItemProps } from "./notification-item";
export default function NotificationDropdown() {
  const socket = useSocket(process.env.NEXT_PUBLIC_ALERTS_SOCKET_URL);
  const L = useLanguage(["NotificationDropdown"]);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const [dropdownState, setDropdownState] = useState(null);
  const [notificationData, setNotificationData] = useState([] as NotificationItemProps[]);
  const [loading, setLoading] = useState(false);
  const [blipActive, setBlipActive] = useState(false);
  const setMessage = usePopup();
  const {
    balanceMutate
  } = useWallet();
  const refAudio = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    refAudio.current = new Audio("/audios/notification.mp3");
  }, []);

  //Used to trigger a mutate on the front to update the users balance
  const currentQueryRef = useRef(router.query);
  useEffect(() => {
    currentQueryRef.current = router.query;
  }, [router]);
  const closeModalIfPixPixpay = useCallback(() => {
    if (currentQueryRef.current?.modal === "wallet" && currentQueryRef.current?.tab === "deposit" && (currentQueryRef.current?.method === "pix" || currentQueryRef.current?.method === "picpay")) {
      router.replace(router.asPath.split("?")[0], undefined, {
        shallow: true
      });
    }
  }, [router]);
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("open-alert", []);
      });
      socket.on("all-notifications", (data: NotificationItemProps[]) => {
        const filteredDeletedNotifications = data.filter(notification => notification.deleted == !1);
        if (data.length === 0) {
          setLoading(false);
        } else {
          setNotificationData(filteredDeletedNotifications);
          setLoading(false);
        }
      });
      socket.on("new-notification", (data: NotificationItemProps) => {
        const blip = data?.blip;
        const read = data?.read;
        const replacements = data?.replacements;
        const title = data?.title;
        const relationId = data?.relation_id;

        // Update balance first for all notifications
        balanceMutate();
        const isFiatDeposit = title.includes("fiat_deposit");
        if (title === "notif_crypto_deposit_confirmed" && replacements && replacements?.TOKEN_AMOUNT) {
          const tokenAmount = replacements?.TOKEN_AMOUNT;
          (window as any).gtag("event", "conversion", {
            send_to: process.env.NEXT_PUBLIC_GTAG_EVENT_ID,
            value: tokenAmount,
            currency: "USD",
            transaction_id: relationId
          });
        }

        // Update notifications list
        setNotificationData(prevNotifications => {
          const exists = prevNotifications.some(notification => notification.id === data.id);
          if (exists) {
            return prevNotifications;
          }
          return [data, ...prevNotifications];
        });
        if (blip !== 0 && read === 0) {
          const isSpecialNotification = data.title === "keys_received" || data.title === "raffle_tickets_earned";
          setMessage({
            itemId: data.id.toString(),
            code: "NotificationDropdown." + data.description,
            replacements: data.replacements,
            raw: isFiatDeposit,
            type: 1,
            fixedDuration: isSpecialNotification ? 1000 : null
          });
        }

        // Play audio for non-special notifications
        if (data.title !== "keys_received" && data.title !== "raffle_tickets_earned") {
          refAudio.current.play();
        }

        //Closes the modal if the user is on the pix or pixpay modal
        if (data.title === "notif_fiat_deposit_complete") {
          closeModalIfPixPixpay();
        }
        setBlipActive(true);
        setDropdownState(data);
      });
      socket.on("unread-notifications", data => {
        if (data) {
          setBlipActive(true);
        }
      });

      //ToDo later, find out if these will cleanup themselves and not create multiple connections
      return () => {
        socket.off("new-notification");
        socket.off("all-notifications");
      };
    }
  }, [closeModalIfPixPixpay, balanceMutate, setMessage, socket]);
  const openCloseDropdown = () => {
    socket.emit("open-alert", notificationData);
    if (dropdownState) {
      dropdownState.blip = 0;
    }
    setBlipActive(false);
    setIsOpen(false);
    setLoading(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return <Popover open={isOpen} onOpenChange={open => {
    if (!open) {
      openCloseDropdown();
    }
    setIsOpen(open);
  }} data-sentry-element="Popover" data-sentry-component="NotificationDropdown" data-sentry-source-file="index.tsx">
      <PopoverTrigger className="relative" data-sentry-element="PopoverTrigger" data-sentry-source-file="index.tsx">
        <div className={buttonVariants({
        variant: "dark",
        className: "w-[48px] h-[48px] !min-h-[48px] !px-0 !py-0 [&_svg]:size-5 relative border-none rounded-none [@media(max-width:450px)]:bg-transparent [@media(max-width:450px)]:px-0 [@media(max-width:450px)]:w-[38px] [@media(max-width:450px)]:hover:!bg-transparent [@media(max-width:320px)]:hidden"
      })} aria-selected={isOpen}>
          <NotificationIcon data-sentry-element="NotificationIcon" data-sentry-source-file="index.tsx" />
        </div>
        {blipActive ? <span className="absolute top-2.5 right-2.5 min-w-[8px] h-[8px] flex items-center justify-center text-[10px] rounded-full border border-solid border-white/50 bg-gradient-to-b from-[#7DD934] to-[#7DD934] text-white shadow-[0px_0px_12px_0px_rgba(0,119,219,0.5),0px_0px_6px_0px_rgba(0,0,0,0.3)] transition-all duration-300 ease animate-ping" /> : null}
      </PopoverTrigger>

      <PopoverContent align="end" className="grid gap-4 w-[415px] [@media(max-width:480px)]:w-[330px] shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2" data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
        <div className="flex flex-col overflow-y-auto gap-[6px] max-h-[400px] [&::-webkit-scrollbar]:w-[5px] [&::-webkit-scrollbar-thumb]:bg-[#494F78] [&::-webkit-scrollbar-thumb]:border-l-4 [&::-webkit-scrollbar-thumb]:border-l-6 [&::-webkit-scrollbar-thumb]:border-solid [&::-webkit-scrollbar-thumb]:border-[#494F78] [&::-webkit-scrollbar-thumb]:bg-clip-padding [&::-webkit-scrollbar-thumb]:rounded-full">
          {loading ? <div className="flex items-center justify-center min-h-[100px]">
              <LoadingSimpleSpinner className="w-5 h-5" />
            </div> : notificationData.length === 0 ? <div className="flex items-center justify-center min-h-[100px]">
              <h4 className="text-sm text-[#7078a3]">
                {L("no_notifications", {})}
              </h4>
            </div> : notificationData?.map(notification => <NotificationItem key={notification.id} notification={notification} setIsOpen={setIsOpen} />)}
        </div>
      </PopoverContent>
    </Popover>;
}