import { useCallback } from "react";
import { formatValueDisplay } from "@lib/tools/convert";
import useWallet from "@hooks/useWallet";
import { useAtom } from "jotai";
import { raffleDetailsAtom } from "../jotai-raffle";
import { useLocale } from "@lib/tools/helpers";

export default function useRaffleConvert() {
  const [raffleDetails] = useAtom(raffleDetailsAtom);
  const locale = useLocale();
  const { activeRate, walletSetting } = useWallet();

  const convertAmount = useCallback(
    (amount) => {
      return formatValueDisplay(amount, {
        rate: {
          rate: raffleDetails?.rates?.[walletSetting] || 1,
          display: { ...activeRate?.display },
        },
        symbol: true,
        decimals: 0,
        locale,
      });
    },
    [activeRate, raffleDetails?.rates, walletSetting, locale]
  );

  return convertAmount;
}

export function useRaffleConvertAmount(rates, walletSetting, activeRate) {
  const convertAmount = useCallback(
    (amount) => {
      return formatValueDisplay(amount, {
        rate: {
          rate: rates?.[walletSetting] || 1,
          display: { ...activeRate?.display },
        },
        symbol: true,
        decimals: 0,
      });
    },
    [rates, walletSetting, activeRate]
  );
  return convertAmount;
}
