import { Reward } from "@/types/rewards";
import RaffleItemDropdown from "@Games/Raffle/components/raffle-drop-down";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import { Button } from "@components/new-ui/button";
import RakebackBoost from "@components/rakeback-boost";
import useLanguage from "@hooks/useLanguage";
import useNow from "@hooks/useNow";
import usePopup from "@hooks/usePopup";
import useRewards from "@hooks/useRewards";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import ValueDisplay from "@items/ValueDisplay";
import { removeDateOffset } from "@lib/utils";
import { NODE_API } from "@lib/api/api";
import { handleCatchPopup } from "@lib/tools/helpers";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { isAfter } from "date-fns";
import Link from "next/link";
import { useRouter } from "next/router";
import { ElementType, startTransition, useCallback, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { CHESTS } from ".";
import { RewardsKeys } from "./rewards-dropdown-keys";
function RewardContentRow({
  countdown,
  refetch,
  hasToken,
  chest,
  hasSufficientFunds,
  hasExpired,
  amount
}: RewardContentRowProps) {
  const {
    balanceMutate
  } = useWallet();
  const setMessage = usePopup();
  const L = useLanguage(["meta", "Rewards", "header"]);
  const {
    mutate,
    addTokenHeader
  } = useUserSession();
  const [isLoading, setIsLoading] = useState(false);
  const setRakebackBoosted = useSetRecoilState(userRakebackBoosted);
  const {
    api,
    image: ImageComponent,
    name
  } = chest;
  const collect = useCallback(async (type: string) => {
    setIsLoading(true);
    let endpoint: string;
    let payload: Record<string, unknown>;
    let messageCode = "responses.su_queue_added";
    switch (type) {
      case "calendar":
        endpoint = "v1/rewards/calendar-claim";
        payload = {
          date: new Date().toISOString().split("T")[0]
        };
        messageCode = "responses.su_reward_chest_no_calendar";
        break;
      case "rakeback":
        endpoint = "v1/rewards/rakeback";
        messageCode = "responses.su_reward_chest_no_calendar";
        break;
      default:
        endpoint = "v1/rewards/chest-claim";
        payload = {
          type
        };
    }
    const cleanup = (data: Record<string, unknown>) => {
      startTransition(async () => {
        await Promise.all([mutate(), refetch(), balanceMutate()]);
        setIsLoading(false);
        if (!data) return;
        setRakebackBoosted(true);
        setMessage({
          type: 1,
          code: type === "calendar" || type === "rakeback" ? data.currency_calendar_reward ? "responses.su_reward_chest" : messageCode : messageCode,
          raw: true,
          replacements: {
            TOKEN_AMOUNT: data.currency_amount_redeemed,
            ...(data.currency_calendar_reward && {
              CALENDAR_REWARD: data.currency_calendar_reward
            }),
            ...(data.currency_instant_reward && {
              INSTANT_REWARD: data.currency_instant_reward
            }),
            ...(data.currency && {
              CURRENCY: data.currency
            })
          }
        });
      });
    };
    return NODE_API.post(endpoint, payload, addTokenHeader()).then(({
      status,
      data
    }) => {
      const isOk = status >= 200 && status < 300;
      if (!isOk) throw data;
      cleanup(data);
    }).catch(e => {
      handleCatchPopup(setMessage)(e);
      cleanup(null);
    });
  }, [addTokenHeader, balanceMutate, mutate, refetch, setMessage, setRakebackBoosted]);
  const onClick = useCallback(() => {
    if (isLoading || !hasToken) return;
    collect(api);
  }, [collect, api, isLoading, hasToken]);
  const formattedCountdown = useMemo(() => {
    if (!countdown) return "";

    // Extract numbers from countdown string (e.g. "6d, 7h, 1m")
    const days = countdown.match(/(\d+)d/)?.[1];
    const hours = countdown.match(/(\d+)h/)?.[1];
    const minutes = countdown.match(/(\d+)m/)?.[1];
    if (days && parseInt(days) > 0) {
      return `${days} ${parseInt(days) === 1 ? L("day", {}) : L("days", {})}`;
    }
    if (hours && parseInt(hours) > 0) {
      return `${hours} ${parseInt(hours) === 1 ? L("hour", {}) : L("hours", {})}`;
    }
    if (minutes && parseInt(minutes) > 0) {
      return `${minutes} ${parseInt(minutes) === 1 ? "min" : "mins"}`;
    }
    return "1 min";
  }, [L, countdown]);
  const shouldCount = hasSufficientFunds || api !== "rakeback" && api !== "calendar" ? formattedCountdown : L("claim", {});
  const canClaim = api === "rakeback" || api === "calendar" ? hasSufficientFunds && hasExpired : hasExpired;
  const btnClaimText = canClaim ? L("claim", {}) : shouldCount || L("claim", {});
  const text = isLoading ? <LoadingSpinner type="button" /> : btnClaimText;
  return <div className="flex items-center gap-4 justify-between rounded-md border border-[#7C83B1] border-opacity-15 border-solid bg-gradient-to-b from-[rgba(124,131,177,0.15)] to-[rgba(94,103,158,0.15)] px-4 py-[10px] pr-3" data-sentry-component="RewardContentRow" data-sentry-source-file="rewards-dropdown-content.tsx">
      <div className="flex items-center gap-[10px]">
        <div className="w-[45px] [&_svg]:w-full">
          <ImageComponent data-sentry-element="ImageComponent" data-sentry-source-file="rewards-dropdown-content.tsx" />
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-[13px] break-normal">{L(name, {})}</span>
          {hasToken && (api === "rakeback" || api === "calendar") && <ValueDisplay amount={amount} size={"small"} classType={["custom-value-display"]} icon={false} symbolPosition />}
        </div>
      </div>

      {hasToken ? <Button onClick={onClick} disabled={!canClaim || isLoading} size="sm" variant={canClaim ? "blue" : "normal"} className="px-[14px]">
          {text}
        </Button> : <Link href={{
      pathname: "",
      query: {
        modal: "auth",
        tab: "login"
      }
    }}>
          <Button variant="blue" size="sm" className="px-[14px]">
            {L("log_in_claim", {})}
          </Button>
        </Link>}
    </div>;
}
export default function RewardsDropdownContent({
  countdowns,
  closeDropdown,
  isRakeBoostActive,
  boostProgress,
  expireAtRakebackBoost
}: RewardsDropdownContentProps) {
  const {
    data: rewardsData,
    mutate: getRewardsData
  } = useRewards();
  const now = useNow();
  const {
    hasToken
  } = useUserSession();
  const L = useLanguage(["header"]);
  const router = useRouter();
  const handleClick = () => {
    closeDropdown();
    setTimeout(() => {
      router.push(`/rewards`);
    }, 100);
  };
  const rewards = useMemo(() => {
    const map = {
      rakeback: rewardsData?.rakeback,
      calendar: rewardsData?.calendar,
      default: (api: string) => rewardsData?.chest_rewards?.[api]
    };
    return CHESTS.map(chest => {
      const reward: Reward = map[chest.api] || map.default(chest.api);
      return {
        chest,
        reward
      };
    });
  }, [rewardsData]);
  return <div data-rewards-dropdown className="relative flex flex-col gap-2 shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2 max-w-[330px] w-[330px]" data-sentry-component="RewardsDropdownContent" data-sentry-source-file="rewards-dropdown-content.tsx">
      <div className="flex flex-col gap-2">
        <RaffleItemDropdown closeDropdown={closeDropdown} data-sentry-element="RaffleItemDropdown" data-sentry-source-file="rewards-dropdown-content.tsx" />

        {isRakeBoostActive ? <RakebackBoost mobile rakebackRate={rewardsData?.rakeback_boost?.rate} boostType={rewardsData?.rakeback_boost?.type} progress={boostProgress} expiresAt={expireAtRakebackBoost} /> : null}

        {rewards.map(({
        chest,
        reward
      }) => <RewardContentRow key={chest.api} refetch={getRewardsData} chest={chest} countdown={countdowns[chest.api] || ""} hasToken={hasToken} hasSufficientFunds={!!reward?.sufficient_funds} hasExpired={isAfter(now, removeDateOffset(reward?.claimable))} amount={reward?.amount} />)}

        {rewardsData?.keys?.count > 0 && <RewardsKeys keys={rewardsData?.keys} closeDropdown={closeDropdown} />}
      </div>

      <div className="h-[1px] w-full bg-white/10 my-[5px]"></div>

      <Button variant="normal" className="w-full h-[42px] text-[13px] min-h-[42px] bg-[#3D446C] hover:bg-[#4F588C] border border-solid hover:text-white text-white border-[#7C83B1] border-opacity-15 hover:border-opacity-50 transition-all duration-300 ease-in-out" onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="rewards-dropdown-content.tsx">
        {L("all_rewards")}
      </Button>
    </div>;
}
type RewardContentRowProps = Readonly<{
  countdown: string;
  refetch: () => void;
  hasToken: boolean;
  chest: {
    image: ElementType;
    name: string;
    api: "rakeback" | "calendar" | "daily" | "weekly" | "monthly";
  };
  hasSufficientFunds: boolean;
  hasExpired: boolean;
  amount?: number;
}>;
type RewardsDropdownContentProps = Readonly<{
  countdowns: Record<string, string>;
  closeDropdown: () => void;
  isRakeBoostActive: boolean;
  boostProgress: number;
  expireAtRakebackBoost;
}>;