const SmallTicketIcon = props => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17" {...props} data-sentry-element="svg" data-sentry-component="SmallTicketIcon" data-sentry-source-file="small-ticket-icon.jsx">
    <path fill="#000" d="M12.334 2.297a.54.54 0 0 1-.08.355 1.154 1.154 0 0 0 1.588 1.588.54.54 0 0 1 .67.073l.341.342a1.62 1.62 0 0 1 0 2.293L13.37 8.432s-.692-.722-.774-.754a.27.27 0 0 0-.363.363c.02.044.764.764.764.764l-6.55 6.55a1.62 1.62 0 0 1-2.293 0l-.316-.315a.54.54 0 0 1-.059-.705 1.151 1.151 0 0 0-1.607-1.607.54.54 0 0 1-.705-.06l-.317-.316a1.62 1.62 0 0 1 .068-2.362L7.7 3.508s.715.742.764.765a.273.273 0 1 0 .331-.434l-.713-.713 1.475-1.475a1.62 1.62 0 0 1 2.294 0l.32.32a.54.54 0 0 1 .164.326" data-sentry-element="path" data-sentry-source-file="small-ticket-icon.jsx"></path>
    <path fill="url(#newGradientId434343)" d="M12.334 2.297a.54.54 0 0 1-.08.355 1.154 1.154 0 0 0 1.588 1.588.54.54 0 0 1 .67.073l.341.342a1.62 1.62 0 0 1 0 2.293L13.37 8.432s-.692-.722-.774-.754a.27.27 0 0 0-.363.363c.02.044.764.764.764.764l-6.55 6.55a1.62 1.62 0 0 1-2.293 0l-.316-.315a.54.54 0 0 1-.059-.705 1.151 1.151 0 0 0-1.607-1.607.54.54 0 0 1-.705-.06l-.317-.316a1.62 1.62 0 0 1 .068-2.362L7.7 3.508s.715.742.764.765a.273.273 0 1 0 .331-.434l-.713-.713 1.475-1.475a1.62 1.62 0 0 1 2.294 0l.32.32a.54.54 0 0 1 .164.326" data-sentry-element="path" data-sentry-source-file="small-ticket-icon.jsx"></path>
    <defs data-sentry-element="defs" data-sentry-source-file="small-ticket-icon.jsx">
      <linearGradient id="newGradientId434343" x1="0.567" x2="10.649" y1="1.069" y2="11.151" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="small-ticket-icon.jsx">
        <stop stopColor="#E2B30F" data-sentry-element="stop" data-sentry-source-file="small-ticket-icon.jsx"></stop>
        <stop offset="1" stopColor="#FCE04D" data-sentry-element="stop" data-sentry-source-file="small-ticket-icon.jsx"></stop>
      </linearGradient>
    </defs>
  </svg>;
export default SmallTicketIcon;