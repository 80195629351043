import tipIcon from "@assets/Images/tip-received-icon.svg";
import Rs3 from "@assets/icons/rs/rs3.png";
import OSRS from "@assets/icons/rs/osrs.png";
import Image from "next/image";
import BTCIcon from "@assets/icons/crypto/BTC";
import ETHIcon from "@assets/icons/crypto/ETH";
import LTCIcon from "@assets/icons/crypto/LTC";
import USDTIcon from "@assets/icons/crypto/USDT";
import VerificationDropdownIcon from "@assets/icons/dropdown/VerificationDropdownIcon";
import bronze from "@assets/Images/bronze-key.png";
import diamond from "@assets/Images/diamond-key.png";
import gold from "@assets/Images/gold-key.png";
import platinum from "@assets/Images/platinum-key.png";
import silver from "@assets/Images/silver-key.png";
export function KeysImages({
  imgType,
  description
}) {
  const keyImages = {
    Bronze: bronze,
    Diamond: diamond,
    Gold: gold,
    Platinum: platinum,
    Silver: silver
  };
  const imgSrc = keyImages[imgType] || "";
  return <Image src={imgSrc} alt={description} width={38} height={38} style={{
    maxWidth: "100%",
    height: "auto"
  }} data-sentry-element="Image" data-sentry-component="KeysImages" data-sentry-source-file="notification-dropdown-icons.tsx" />;
}
const ICON_SIZES = {
  CRYPTO: {
    width: 35,
    height: 35
  },
  GOLD: {
    width: 42,
    height: 42
  },
  RS3: {
    width: 42,
    height: 30
  },
  DEFAULT: {
    width: 35,
    height: 35
  },
  CUSTOM: {
    width: 53,
    height: 53
  }
};
const CRYPTO_ICONS = {
  BTC: BTCIcon,
  ETH: ETHIcon,
  LTC: LTCIcon,
  USDT: USDTIcon
};
const getKYCIcon = () => <VerificationDropdownIcon fill="#C1C4DC" width={30} height={30} data-sentry-element="VerificationDropdownIcon" data-sentry-component="getKYCIcon" data-sentry-source-file="notification-dropdown-icons.tsx" />;
const getKeysIcon = (type, description) => <KeysImages imgType={type} description={description} data-sentry-element="KeysImages" data-sentry-component="getKeysIcon" data-sentry-source-file="notification-dropdown-icons.tsx" />;
const getCryptoIcon = cryptoCode => {
  const CryptoIconComponent = CRYPTO_ICONS[cryptoCode];
  return CryptoIconComponent ? <CryptoIconComponent {...ICON_SIZES.CRYPTO} data-sentry-element="CryptoIconComponent" data-sentry-component="getCryptoIcon" data-sentry-source-file="notification-dropdown-icons.tsx" /> : null;
};
const getGoldIcon = goldCode => {
  const iconMap = {
    OSRS: {
      src: OSRS,
      ...ICON_SIZES.GOLD
    },
    RS3: {
      src: Rs3,
      ...ICON_SIZES.RS3
    }
  };
  const iconConfig = iconMap[goldCode];
  return iconConfig ? <Image src={iconConfig.src} alt={goldCode} {...iconConfig} style={{
    maxWidth: "100%",
    height: "auto"
  }} data-sentry-element="Image" data-sentry-component="getGoldIcon" data-sentry-source-file="notification-dropdown-icons.tsx" /> : null;
};
const getDefaultIcon = notification => {
  const src = notification.icon || tipIcon;
  const size = notification.icon ? ICON_SIZES.CUSTOM : ICON_SIZES.DEFAULT;
  return <Image src={src} alt={notification.description} {...size} style={{
    maxWidth: "100%",
    height: "auto"
  }} data-sentry-element="Image" data-sentry-component="getDefaultIcon" data-sentry-source-file="notification-dropdown-icons.tsx" />;
};
export default function NotificationsIcons({
  notification
}) {
  const getIconComponent = () => {
    if (notification.description?.startsWith("kyc_")) {
      return getKYCIcon();
    }
    if (notification.description === "keys_received_description") {
      return getKeysIcon(notification.replacements.TYPE, notification.description);
    }
    const cryptoIcon = getCryptoIcon(notification.replacements?.CRYPTO_CODE);
    if (cryptoIcon) return cryptoIcon;
    const goldIcon = getGoldIcon(notification.replacements?.GOLD_CODE);
    if (goldIcon) return goldIcon;
    return getDefaultIcon(notification);
  };
  return <div className="h-full flex items-start relative min-w-[35px]" data-sentry-component="NotificationsIcons" data-sentry-source-file="notification-dropdown-icons.tsx">
      {getIconComponent()}
    </div>;
}