import { Button } from "@components/new-ui/button";
import useLanguage from "@hooks/useLanguage";
import CurrencyIcons from "@items/CurrencyIcons";
import ToolTip from "@items/ToolTip";
import ValueDisplay from "@items/ValueDisplay";
import { differenceInMinutes, format, fromUnixTime } from "date-fns";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, ReactNode, SetStateAction } from "react";
import NotificationsIcons from "./notification-dropdown-icons";
const noReplacements = {};
const getReplacements = (notification: NotificationItemProps) => {
  try {
    const replaceWithJSX = (key: string) => {
      switch (key) {
        case "CURRENCY_AMOUNT":
        case "TOKEN_AMOUNT":
        case "INSTANT_REWARD":
        case "CALENDAR_REWARD":
          {
            const amount = notification.replacements?.["CURRENCY_AMOUNT"] || notification.replacements?.[key];
            return <ValueDisplay amount={amount || 0} currencyCode={notification.replacements?.CURRENCY} raw={notification.description.includes("fiat_deposit")} />;
          }
        case "USER_NAME":
          return <span>{notification.replacements?.[key] || "user"}</span>;
        case "CRYPTO_AMOUNT":
          {
            const code = notification.replacements?.["CRYPTO_CODE"];
            if (!code) return null;
            return <span>
              <span>
                <CurrencyIcons code={code?.toLowerCase()} size={"small"} />
              </span>

              {notification.replacements?.[key]}
            </span>;
          }
        default:
          return notification.replacements[key];
      }
    };
    const replacements = notification.replacements;
    const additions = replacements && typeof replacements === "object" ? Object.keys(replacements).reduce((acc, key) => ({
      ...acc,
      [key]: replaceWithJSX(key)
    }), {} as Record<string, ReactNode | null>) : {};
    const result = {
      ...replacements,
      ...additions
    };
    return result;
  } catch {
    return noReplacements;
  }
};
export function NotificationItem({
  notification,
  setIsOpen
}: {
  notification: NotificationItemProps;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const L = useLanguage(["NotificationDropdown"]);
  const staticTime = unixTimestamp => {
    const date = fromUnixTime(unixTimestamp);
    return format(date, "MMM d, yyyy, h:mm a");
  };
  const relativeTime = unixTimestamp => {
    const now = new Date();
    const notificationTime = fromUnixTime(unixTimestamp);

    // Calculate the difference in minutes between now and the notification time
    const minutesDiff = differenceInMinutes(now, notificationTime);

    // Customize the output based on the time difference
    if (minutesDiff < 1) {
      return "Just now";
    } else {
      return formatDistanceToNow(notificationTime, {
        addSuffix: true
      });
    }
  };
  return <div className="pl-4 pr-3 mr-[6px] py-3.5 bg-gradient-to-b flex items-center gap-[14px] from-[#7b83b1]/15 to-[#5e679e]/15 rounded-md border border-solid border-[#7b83b1]/15" key={notification.id} data-sentry-component="NotificationItem" data-sentry-source-file="notification-item.tsx">
      <NotificationsIcons notification={notification} data-sentry-element="NotificationsIcons" data-sentry-source-file="notification-item.tsx" />

      <div className="flex flex-col gap-[8px]">
        <div className="flex items-center gap-2">
          <h4 className="text-[13px] font-medium text-white">
            {notification.title === "keys_received" ? L(notification.title, {
            TYPE: notification.replacements.TYPE
          }) : L(notification.title, {})}
          </h4>
          {notification.read === 0 ? <div className="text-[#db9a4e] text-[9px] bg-[rgba(219,154,78,0.25)] px-[7px] h-[15px] flex items-center justify-center rounded-[3px]">
              {L("new", {})}
            </div> : null}
        </div>

        <div className="text-[13px] text-[#E8E5FF]/75 leading-[1.3] font-normal">
          {L(notification.description, getReplacements(notification))}
        </div>

        <div className="flex">
          <ToolTip placement="top" text={staticTime(notification.time)} size="small" transparent data-sentry-element="ToolTip" data-sentry-source-file="notification-item.tsx">
            <span className="text-[12px] text-[#6971A6]">
              {relativeTime(notification.time)}
            </span>
          </ToolTip>
        </div>
      </div>

      {notification?.url ? notification.title === "keys_received" ? <div className="ml-auto">
            <Button variant="normal" size="sm" onClick={() => {
        setIsOpen(false);
        router.push(notification.url);
      }}>
              Open Case
            </Button>
          </div> : <div className="ml-auto">
            <Link href={notification?.url} legacyBehavior>
              <Button variant="normal" size="sm" onClick={() => setIsOpen(false)}>
                Open
              </Button>
            </Link>
          </div> : null}
    </div>;
}
export type NotificationItemProps = Readonly<{
  id: number;
  title: string;
  description: string;
  icon: any;
  url: string;
  url_text: string;
  replacements: Record<string, any>;
  time: number;
  blip: number;
  read: number;
  deleted: number | boolean;
  relation_id: number;
}>;