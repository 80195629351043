import React, { useId } from "react";
import st from "@styles/components/RakebackBoost.module.scss";
export default function RakebackProgress({
  size = 25,
  strokeWidth = 1.5,
  progress = 0
}: {
  size?: number;
  strokeWidth?: number;
  progress?: number;
}) {
  const actualSize = Math.max(size, 20);
  const p = progress || 100;
  const radius = (actualSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = +circumference - +p / 100 * +circumference;
  const rotate = (1 - p / 100) * 360 - 90;
  const width = actualSize;
  const height = actualSize + 4;
  const baseSize = 25;
  const scale = actualSize / baseSize;
  const id = useId();
  return <div className={st["svg-container"]} data-sentry-component="RakebackProgress" data-sentry-source-file="rakeback-progress.tsx">
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height - 4}`} xmlns="http://www.w3.org/2000/svg" fill="none" data-sentry-element="svg" data-sentry-source-file="rakeback-progress.tsx">
        <g filter={`url(#filter0_d_${id}_17393_1315811)`} transform={`translate(${width / 2 - 12.8 * scale}, ${height / 2 - 15.3 * scale}) scale(${scale})`} data-sentry-element="g" data-sentry-source-file="rakeback-progress.tsx">
          <path fill={`url(#paint0_linear_${id}_17393_1315812)`} d="m14.6 11.353-.394 8.255a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392l-.393-8.255H8.854a.412.412 0 0 1-.319-.673l3.706-4.529a.412.412 0 0 1 .638 0l3.705 4.53a.412.412 0 0 1-.318.672zm1.665 8.262a.412.412 0 1 1-.822-.053l.412-6.383a.412.412 0 1 1 .822.053zm-6.588-.053a.412.412 0 1 1-.822.053l-.412-6.383a.412.412 0 1 1 .822-.053z" data-sentry-element="path" data-sentry-source-file="rakeback-progress.tsx" />
          <path stroke={`url(#paint1_linear_${id}_17393_1315814)`} strokeWidth="0.4" d="M14.218 10.953h2.054zm0 0-.018.38m.018-.38-.018.38m0 0-.393 8.256m.393-8.255-.393 8.255m0 0a.012.012 0 0 1-.012.011m.012-.011-.012.011m.411.008.394-8.255h1.666a.412.412 0 0 0 .318-.673zm0 0a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392z" data-sentry-element="path" data-sentry-source-file="rakeback-progress.tsx" />
        </g>

        <circle cx={actualSize / 2} cy={actualSize / 2} r={radius} fill="none" strokeWidth={strokeWidth} strokeLinecap="round" strokeDasharray={circumference} strokeDashoffset={0} strokeOpacity={0.2} stroke="#fff" data-sentry-element="circle" data-sentry-source-file="rakeback-progress.tsx" />
        <circle cx={actualSize / 2} cy={actualSize / 2} r={radius} fill="none" strokeWidth={strokeWidth} strokeLinecap="round" strokeDasharray={circumference} strokeDashoffset={strokeDashoffset} stroke={`url(#paint2_linear_${id}_16614_35042)`} // Referencing the gradient here
      transform={`rotate(${rotate}, ${actualSize / 2}, ${actualSize / 2})`} data-sentry-element="circle" data-sentry-source-file="rakeback-progress.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="rakeback-progress.tsx">
          <linearGradient id={`paint0_linear_${id}_17393_1315812`} x1="12.56" x2="12.56" y1="6" y2="20" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="rakeback-progress.tsx">
            <stop stopColor="#FFB300" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
            <stop offset="1" stopColor="#FFB300" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
          </linearGradient>
          <linearGradient id={`paint1_linear_${id}_17393_1315814`} x1="12.56" x2="12.56" y1="6" y2="20" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="rakeback-progress.tsx">
            <stop stopColor="#fff" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.1" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
          </linearGradient>
          <linearGradient id={`paint2_linear_${id}_16614_35042`} x1="13.7486" y1="1.5275" x2="11.1154" y2="23.4856" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="rakeback-progress.tsx">
            <stop stopColor="#FFD166" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
            <stop offset="1" stopColor="#FFB300" data-sentry-element="stop" data-sentry-source-file="rakeback-progress.tsx" />
          </linearGradient>
          <filter id={`filter0_d_${id}_17393_1315811`} width="20.236" height="26" x="2.441" y="3" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" data-sentry-element="filter" data-sentry-source-file="rakeback-progress.tsx">
            <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="rakeback-progress.tsx" />
            <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="rakeback-progress.tsx" />
            <feOffset dy="3" data-sentry-element="feOffset" data-sentry-source-file="rakeback-progress.tsx" />
            <feGaussianBlur stdDeviation="3" data-sentry-element="feGaussianBlur" data-sentry-source-file="rakeback-progress.tsx" />
            <feComposite in2="hardAlpha" operator="out" data-sentry-element="feComposite" data-sentry-source-file="rakeback-progress.tsx" />
            <feColorMatrix values="0 0 0 0 0.112831 0 0 0 0 0.145539 0 0 0 0 0.227311 0 0 0 1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="rakeback-progress.tsx" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17393_131581" data-sentry-element="feBlend" data-sentry-source-file="rakeback-progress.tsx" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_17393_131581" result="shape" data-sentry-element="feBlend" data-sentry-source-file="rakeback-progress.tsx" />
          </filter>
        </defs>
      </svg>
    </div>;
}