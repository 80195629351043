import st from "./raffle-drop-down.module.scss";
import { useAtomValue } from "jotai";
import MiniTicketIcon from "../../assets/mini-ticket.png";
import Image from "next/image";
import { useDict } from "@hooks/useLanguage";
import SmallTicketIcon from "../../assets/small-ticket-icon.jsx";
import useSWR from "swr";
import { userRaffleDataAtom } from "@Games/Raffle/jotai-raffle";
import useRaffleConvert from "@Games/Raffle/helpers/use-raffle-convert";
import { NODE_API } from "@lib/api/api";
import { useRouter } from "next/router";
import useToken from "@hooks/useToken";
export default function RaffleItemDropdown({
  closeDropdown
}) {
  const userRaffleData = useAtomValue(userRaffleDataAtom);
  const dict = useDict("raffle");
  const convertRaffleValue = useRaffleConvert();
  const router = useRouter();
  const hasToken = !!useToken();
  const {
    data: userTicketsData
  } = useSWR(hasToken ? "/v1/raffles/my-tickets" : null, url => NODE_API.get(url).then(res => res?.data));
  const handleClick = () => {
    closeDropdown();
    setTimeout(() => {
      router.push("/raffle");
    }, 100);
  };
  return <div onClick={handleClick} className={st["raffle-drop-down-container"]} data-sentry-component="RaffleItemDropdown" data-sentry-source-file="index.jsx">
      <div className={st["left-side"]}>
        <div className={st["icon-container"]}>
          <Image src={MiniTicketIcon} width={45} height={45} priority alt="mini-ticket-icon" data-sentry-element="Image" data-sentry-source-file="index.jsx" />
        </div>
        <div className={st["heading-container"]}>
          <span className={st["title"]}>{convertRaffleValue(20000)}</span>
          {dict({
          name: "weekly_raffle",
          as: "span",
          className: st["sub-title"]
        })}
        </div>
      </div>
      <div className={st["right-side"]}>
        <SmallTicketIcon data-sentry-element="SmallTicketIcon" data-sentry-source-file="index.jsx" />
        <span>
          {(userTicketsData?.tickets?.length || userRaffleData?.ticketCount) ?? 0}
        </span>
      </div>
    </div>;
}