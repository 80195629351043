import React from "react";
import st from "@styles/components/RakebackBoost.module.scss";
import { Dict } from "@hooks/useLanguage";
import InfoBordered from "@assets/icons/general/InfoBordered";
import { useState } from "react";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";
import { useAtomValue } from "jotai";
import { cn } from "@lib/utils";
import RakebackProgress from "./rakeback-progress";
const formattedTime = (leftTime: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const {
    days,
    hours,
    minutes,
    seconds
  } = leftTime;
  const totalHours = days * 24 + hours;
  const formattedHours = String(totalHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
export default function RakebackBoost({
  progress,
  expiresAt,
  rakebackRate,
  boostType,
  mobile = false,
  strokeWidth = 2.5,
  header = false
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isRewardsDropdownOpen = useAtomValue(activeRewardsDropdownAtom);
  if (header) {
    return <div className={cn(st["container-header"], st["container"], isRewardsDropdownOpen && "pointer-events-none")}>
        <RakebackProgress size={25} progress={progress} strokeWidth={strokeWidth} />

        <div className={st["info"]}>
          <div className={st["label"]}>{rakebackRate}% Boost</div>
          <p className={st["time"]}>{formattedTime(expiresAt)}</p>
        </div>

        <RakebackBoostTooltip rakebackRate={rakebackRate} boostType={boostType} />
      </div>;
  }
  return <div className={`${st["container"]} ${st["dropdown-version"]} ${mobile && st["mobile"]} ${st["no-hover"]} ${isTooltipOpen && st["info-hovered"]}`} data-sentry-component="RakebackBoost" data-sentry-source-file="index.tsx">
      <RakebackProgress size={42} progress={progress} strokeWidth={mobile ? 1.5 : strokeWidth} data-sentry-element="RakebackProgress" data-sentry-source-file="index.tsx" />

      <div className={st["info"]}>
        <div className={st["label"]}>{rakebackRate}% Boost</div>
        <p className={`${st["time"]} flex items-center gap-2`}>
          {formattedTime(expiresAt)}
          <InfoBordered className="cursor-pointer !w-[18px] !h-[18px]" onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)} data-sentry-element="InfoBordered" data-sentry-source-file="index.tsx" />
        </p>
      </div>

      <RakebackBoostTooltip rakebackRate={rakebackRate} boostType={boostType} data-sentry-element="RakebackBoostTooltip" data-sentry-source-file="index.tsx" />
    </div>;
}
export function RakebackBoostTooltip({
  rakebackRate,
  boostType
}) {
  return <div className={`${st["tooltip"]}`} data-sentry-component="RakebackBoostTooltip" data-sentry-source-file="index.tsx">
      <span className={st["tooltip-text"]}>
        <Dict name="rakeboost_tooltip_label" className={st["rake-label"]} as="span" section="Rewards" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        <Dict name="rakeboost_tooltip" as="span" replacements={{
        rate: rakebackRate
      }} section="Rewards" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        <Dict name={`BoostType.${boostType}`} as="span" className={st["boost-type"]} section="Rewards" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
      </span>
    </div>;
}