import { RewardsData } from "@/types/rewards";
import { RewardsKeyIcon } from "@assets/svgs/RewardsIcons";
import { Button } from "@components/new-ui/button";
import { getMessage } from "@hooks/use-messages";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
type RewardsKeysProps = Readonly<{
  keys: RewardsData["keys"];
  closeDropdown: () => void;
}>;
export function RewardsKeys({
  keys,
  closeDropdown
}: RewardsKeysProps) {
  const router = useRouter();
  const handleClick = () => {
    closeDropdown();
    setTimeout(() => {
      router.push(`/rewards/${keys.best_type}`);
    }, 100);
  };
  return <div className="flex items-center gap-4 justify-between rounded-md border border-[#9CEC60] border-opacity-50 border-solid bg-gradient-to-t from-[#9CEC60]/15 to-[#7C83B1]/10 bg-opacity-20 px-4 py-[10px] pr-3" data-sentry-component="RewardsKeys" data-sentry-source-file="rewards-dropdown-keys.tsx">
      <div className="flex items-center gap-[10px]">
        <div className="w-[45px] [&_svg]:w-full">
          <RewardsKeyIcon data-sentry-element="RewardsKeyIcon" data-sentry-source-file="rewards-dropdown-keys.tsx" />
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-[13px] break-normal [&>strong]:font-semibold">
            <FormattedMessage {...getMessage("Rewards_dropdown_keys")} values={{
            KEYS: keys.count,
            strong: chunks => <strong>{chunks}</strong>
          }} data-sentry-element="FormattedMessage" data-sentry-source-file="rewards-dropdown-keys.tsx" />
          </span>
        </div>
      </div>

      <Button size="sm" variant="green" className="px-[14px]" onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="rewards-dropdown-keys.tsx">
        <FormattedMessage {...getMessage("Rewards_dropdown_keys_open")} data-sentry-element="FormattedMessage" data-sentry-source-file="rewards-dropdown-keys.tsx" />
      </Button>
    </div>;
}